import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from "@mui/material";
import React from "react";
import { TransitionProps } from "@mui/material/transitions";
import { useGlobalContext } from "../../context/GlobalContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CancellationPolicyDialog() {
  const globalContext = useGlobalContext();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={globalContext.showCancellationPolicyDialog}
      TransitionComponent={Transition}
      keepMounted
      fullScreen={fullScreen}
      onClose={() => globalContext.setShowCancellationPolicyDialog(false)}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: { borderRadius: fullScreen ? 0 : 15, },
      }}
    >
      <DialogTitle fontSize={30} fontWeight={800} sx={{ p: { xs: 2, md: 3 } }}>
        Cancellation Policy
      </DialogTitle>
      <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
        <Typography>
          We understand plans change, and sometimes you may need to cancel a
          rental. If you need to cancel, the first step is to let us know.
          <br></br>
          <br></br>
          Cancellations can be disruptive for both renters and owners, so in
          cases we need to impose penalties.
          <br></br>
          <br></br>
          If the cancellation issue is from our side, We will prefer to provide
          you with the best available alternative so that your plans don’t
          suffer. If the things don’t work out, then the entire booking amount
          shall be refunded within 7 working days.
          <br></br>
          <br></br>
          If the cancellation is from your side, following penalties shall be
          imposed on the booking amount:
          <br></br>
          <br></br>
          <li>
            More than 24 hours - <b>25% of the booking amount</b>
          </li>
          <li>
            Less than 24 hours - <b>NO REFUND of Booking Amount</b>
          </li>
          <br></br>
          The remaining amount shall be refunded within 7 working days to the
          original source of payment.
        </Typography>
        <br></br>
        <Typography variant={"h6"}>Contact Details</Typography>
        <Typography>
          <li>
            <b>Phone:</b>+91 93612 71285
          </li>
          <li>
            <b>Email:</b>signaturemomentsllp@gmail.com
          </li>
          <li>
            <b>Whatsapp:</b>+91 93612 71285
          </li>
          <li>
            <b>Instagram:</b>slatepencil_sm
          </li>
          <li>
            <b>Address:</b>16A New No: 26B Kongu Nagar, Ramanathapuram,
            Coimbatore - 641005.
          </li>
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          size={"large"}
          fullWidth
          variant="contained"
          sx={{ borderRadius: 2 }}
          onClick={() => globalContext.setShowCancellationPolicyDialog(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
