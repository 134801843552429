import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from "@mui/material";
import React from "react";
import { TransitionProps } from "@mui/material/transitions";
import { useGlobalContext } from "../../context/GlobalContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AboutUsDialog() {
  const globalContext = useGlobalContext();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={globalContext.showAboutUsDialog}
      TransitionComponent={Transition}
      keepMounted
      fullScreen={fullScreen}
      onClose={() => globalContext.setShowAboutUsDialog(false)}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: { borderRadius: fullScreen ? 0 : 15, },
      }}
    >
      <DialogTitle fontSize={30} fontWeight={800} sx={{ p: { xs: 2, md: 3 } }}>
        About Us
      </DialogTitle>
      <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
        <Typography>
          <b>Slate Pencil</b> is a premium media equipment rental company
          located in the city of <b>Coimbatore</b>. We rent media gear like{" "}
          <b>Cameras, Lenses, Lights, and Accessories</b>. We provide media
          equipment for rentals at affordable prices. Enterprise renting is also
          supported based on gear availability.
          <br></br>
          <br></br>
          Our team of expert photographers and videographers is here to ensure
          that you rent the right gear every time. We work meticulously to
          clean, inspect, test and prep every order so you have the right gear
          every time, with no surprises. Because that's exactly how we would
          want it if it was our shoot.
          <br></br>
          <br></br>
          We are photographers and videographers just like you. Whether you know
          exactly what you want, need advice putting together a rig, or just
          want to talk trade, we are up for anything.
          <br></br>
          <br></br>
          We have thousands and thousands of photo and video items in stock so
          you can get your hands on exactly what you need when you need it. From
          the tried & true RED Komodo to the latest GoPro, we've got you
          covered.
          <br></br>
          <br></br>
          <b>Thank you!</b>
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          size={"large"}
          fullWidth
          variant="contained"
          sx={{ borderRadius: 2 }}
          onClick={() => globalContext.setShowAboutUsDialog(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
