import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from "@mui/material";
import React from "react";
import { TransitionProps } from "@mui/material/transitions";
import { useGlobalContext } from "../../context/GlobalContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PrivacyPolicyDialog() {
  const globalContext = useGlobalContext();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={globalContext.showPrivacyPolicyDialog}
      TransitionComponent={Transition}
      keepMounted
      fullScreen={fullScreen}
      onClose={() => globalContext.setShowPrivacyPolicyDialog(false)}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: { borderRadius: fullScreen ? 0 : 15, },
      }}
    >
      <DialogTitle fontSize={30} fontWeight={800} sx={{ p: { xs: 2, md: 3 } }}>
        Privacy Policy
      </DialogTitle>
      <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
        <b>SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</b>
        <br></br>
        &emsp;&emsp;When you purchase something from our website, as part of the
        retailing process, we collect the personal information you give us such
        as your name, phone, and email address. When you browse our store, we
        also automatically receive your computer’s internet protocol (IP)
        address in order to provide us with information that helps us learn
        about your browser and operating system.
        <br></br>
        <br></br>
        <b>SECTION 2 – CONSENT</b>
        <br></br>
        &emsp;&emsp;How do you get my consent? When you provide us with personal
        information to complete a transaction, verify your credit card, place an
        order, arrange for a delivery or return a purchase, we imply that you
        consent to our collecting it and using it for that specific reason only.
        If we ask for your personal information for a secondary reason, like
        marketing, we will either ask you directly for your expressed consent or
        provide you with an opportunity to say no.
        <br></br>
        <br></br>
        <b>SECTION 3 – DISCLOSURE</b>
        <br></br>
        &emsp;&emsp;We may disclose your personal information if we are required
        by law to do so or if you violate our Terms of Service.
        <br></br>
        <br></br>
        <b>SECTION 4 – PAYMENT</b>
        <br></br>
        &emsp;&emsp;We use Razorpay for processing payments. We/Razorpay do not
        store your card data on their servers. The data is encrypted through the
        Payment Card Industry Data Security Standard (PCI-DSS) when processing
        payments. Your purchase transaction data is only used as long as is
        necessary to complete your purchase transaction. After that is complete,
        your purchase transaction information is not saved. Our payment gateway
        adheres to the standards set by PCI-DSS as managed by the PCI Security
        Standards Council, which is a joint effort of brands like Visa,
        MasterCard, American Express, and Discover. PCI-DSS requirements help
        ensure the secure handling of credit card information by our store and
        its service providers. For more insight, you may also want to read the
        terms and conditions of Razorpay at https://razorpay.com/privacy/
        <br></br>
        <br></br>
        <b>SECTION 5 - THIRD-PARTY SERVICES</b>
        <br></br>
        &emsp;&emsp;In general, the third-party providers used by us will only
        collect, use and disclose your information to the extent necessary to
        allow them to perform the services they provide to us. However, certain
        third-party service providers, such as payment gateways and other
        payment transaction processors, have their own privacy policies with
        respect to the information we are required to provide to them for your
        purchase-related transactions. For these providers, we recommend that
        you read their privacy policies so you can understand the manner in
        which your personal information will be handled by these providers. In
        particular, remember that certain providers may be located in or have
        facilities that are located in a different jurisdiction than either you
        or us. So if you elect to proceed with a transaction that involves the
        services of a third-party service provider, then your information may
        become subject to the laws of the jurisdiction(s) in which that service
        provider or its facilities are located. Once you leave our store’s
        website or are redirected to a third-party website or application, you
        are no longer governed by this Privacy Policy or our website’s Terms of
        Service. Links When you click on links on our store, they may direct you
        away from our site. We are not responsible for the privacy practices of
        other sites and encourage you to read their privacy statements.
        <br></br>
        <br></br>
        <b>SECTION 6 – SECURITY</b>
        <br></br>
        &emsp;&emsp;To protect your personal information, we take reasonable
        precautions and follow industry best practices to make sure it is not
        inappropriately lost, misused, accessed, disclosed, altered, or
        destroyed.
        <br></br>
        <br></br>
        <b>SECTION 7 – COOKIES</b>
        <br></br>
        &emsp;&emsp;We use cookies to maintain the session of your user. It is
        not used to personally identify you on other websites.
        <br></br>
        <br></br>
        <b>SECTION 8 - AGE OF CONSENT</b>
        <br></br>
        &emsp;&emsp;By using this site, you represent that you are at least the
        age of majority in your state or province of residence, or that you are
        the age of majority in your state or province of residence and you have
        given us your consent to allow any of your minor dependents to use this
        site.
        <br></br>
        <br></br>
        <b>SECTION 9 - CHANGES TO THIS PRIVACY POLICY</b>
        <br></br>
        &emsp;&emsp;We reserve the right to modify this privacy policy at any
        time, so please review it frequently. Changes and clarifications will
        take effect immediately upon their posting on the website. If we make
        material changes to this policy, we will notify you here that it has
        been updated, so that you are aware of what information we collect, how
        we use it, and under what circumstances, if any, we use and/or disclose
        it. If our store is acquired or merged with another company, your
        information may be transferred to the new owners so that we may continue
        to lease products to you.
        <br></br>
        <br></br>
        <b>QUESTIONS AND CONTACT INFORMATION</b>
        <br></br>
        &emsp;&emsp;If you would like to: access, correct, amend or delete any
        personal information we have about you, register a complaint, or simply
        want more information contact our Privacy Compliance Officer at{" "}
        <b>signaturemomentsllp@gmail.com</b> or mail us at{" "}
        <b>
          16A New No: 26B Kongu Nagar, Ramanathapuram, Coimbatore - 641 005.
        </b>
        <br></br>
        <br></br>
        <Typography variant={"h6"}>Contact Details</Typography>
        <Typography>
          <li>
            <b>Phone:</b>+91 93612 71285
          </li>
          <li>
            <b>Email:</b>signaturemomentsllp@gmail.com
          </li>
          <li>
            <b>Whatsapp:</b>+91 93612 71285
          </li>
          <li>
            <b>Instagram:</b>slatepencil_sm
          </li>
          <li>
            <b>Address:</b>16A New No: 26B Kongu Nagar, Ramanathapuram,
            Coimbatore - 641005.
          </li>
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          size={"large"}
          fullWidth
          variant="contained"
          sx={{ borderRadius: 2 }}
          onClick={() => globalContext.setShowPrivacyPolicyDialog(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
