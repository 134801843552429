import {Box, Container, Divider, Grid, Link, Typography} from "@mui/material";
import AboutUsDialog from "../components/dialogs/AboutUsDialog";
import ContactUsDialog from "../components/dialogs/ContactUsDialog";
import PrivacyPolicyDialog from "../components/dialogs/PrivacyPolicyDialog";
import TermsAndConditionsDialog from "../components/dialogs/TermsAndConditionsDialog";
import CancellationPolicyDialog from "../components/dialogs/CancellationPolicyDialog";
import React from "react";
import {useGlobalContext} from "../context/GlobalContext";

export default function Footer() {
    const globalContext = useGlobalContext();
    return (
        <Box component="footer" sx={{
            backgroundColor: 'primary.main',
            pt: 6,
            pb: 1
        }}>
            <Container maxWidth="lg" sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                <img src={'logo.png'} width={'100px'} alt='footer'/>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color="primary.light"
                    component="p"
                    sx={{pt: 2}}
                >
                    Real people, Real stories, Real moments.
                </Typography>
                <Grid container>
                    <Grid item xs={12} sx={{pt: 2, pl: 6, pr: 6, pb: 3}}>
                        <Divider sx={{backgroundColor: 'white'}}/>
                    </Grid>
                    <Grid item xs={12} md={3} sx={{textAlign: 'center', fontWeight: 'bold'}}>
                        <Link color="primary.light" onClick={() => globalContext.setShowAboutUsDialog(true)}>
                            About Us
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={3} sx={{textAlign: 'center', fontWeight: 'bold', pt: {xs: 1, md: 0}}}>
                        <Link color="primary.light" onClick={() => globalContext.setShowContactUsDialog(true)}>
                            Contact Us
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={3} sx={{textAlign: 'center', fontWeight: 'bold', pt: {xs: 1, md: 0}}}>
                        <Link color="primary.light" onClick={() => globalContext.setShowPrivacyPolicyDialog(true)}>
                            Privacy Policy
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={3} sx={{textAlign: 'center', fontWeight: 'bold', pt: {xs: 1, md: 0}}}>
                        <Link color="primary.light" onClick={() => globalContext.setShowCancellationPolicyDialog(true)}>
                            Cancellation Policy
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{textAlign: 'center', fontWeight: 'bold', pt: {xs: 1, md: 3}}}>
                        <Link color="primary.light" onClick={() => globalContext.setShowTermsAndConditionsDialog(true)}>
                            Terms and Conditions
                        </Link>
                    </Grid>
                </Grid>
                <Typography variant="body2" color="primary.light" align="center" sx={{pt: 3}}>
                    {'Copyright © '}
                    <Link color="inherit" href="https://signaturemoments.co.in/">
                        Signature Moments
                    </Link>{' '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography>
                <AboutUsDialog />
                <ContactUsDialog />
                <PrivacyPolicyDialog />
                <TermsAndConditionsDialog />
                <CancellationPolicyDialog />
            </Container>
        </Box>
    );
}