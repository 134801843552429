import {
    CssBaseline,
    Grid,
    Fab
} from "@mui/material";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {createTheme, ThemeProvider} from "@mui/material";
import {useEffect, useState} from "react";
import HomePageSearch from "../components/HomePageSearch";
import EquipmentList from "../components/EquipmentList";
import Footer from "../components/Footer";
import {GlobalContext} from "../context/GlobalContext";
import CheckoutDialog from "../components/dialogs/CheckoutDialog";
import PaymentVerificationDialog from "../components/dialogs/PaymentVerificationDialog";
import AlertDialog from "../components/dialogs/AlertDialog";
import Equipment from "../data/Equipment";
import CircularLoader from "../components/loaders/CircularLoader";
import axios from "axios";
import {intervalToDuration} from "date-fns";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ProfileDialog from "../components/dialogs/ProfileDialog";
import { CookiesProvider, useCookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";
import ContentCafeCustomizationDialog from "../components/dialogs/ContentCafeCustomizationDialog";
import PodcastCafeCustomizationDialog from "../components/dialogs/PodcastCafeCustomizationDialog";

const globalTheme = createTheme({
    palette: {
        primary: {
            light: '#ffffff',
            main: '#0c0a0b',
            dark: '#0c0a0b',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#0c0a0b',
            main: '#0c0a0b',
            dark: '#0c0a0b',
            contrastText: '#000',
        },
    },
    typography: {
        fontFamily: [
            'Google Sans',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    }
});

function removeLoader() {
    setTimeout(function () {
        document.getElementById('loader')?.classList.add('fade-out');
    }, 2000);
    setTimeout(function () {
        document.getElementById('loader')?.remove();
    }, 2500);
}

const haversineDistance = (coords1: [number, number], coords2: [number, number]): number => {
    const toRadians = (degrees: number): number => (degrees * Math.PI) / 180;
    const [lat1, lon1] = coords1;
    const [lat2, lon2] = coords2;
    const R = 6371;
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
};

const isNearest = (targetCoords: [number, number], coords1: [number, number], coords2: [number, number]): boolean => {
    return haversineDistance(targetCoords, coords1) < haversineDistance(targetCoords, coords2);
};

export default function Home() {
    removeLoader();

    const [searchParams, setSearchParams] = useSearchParams();

    const [cookies] = useCookies(["name", "phone", "email", "picture"]);
    const [showLoader, setShowLoader] = useState(false);
    const [startDate, setStartDate] = useState<Date>(new Date('01/01/2000 12:00 am'));
    const [endDate, setEndDate] = useState<Date>(new Date('01/01/2000 12:00 am'));
    const [type, setType] = useState("Cameras");
    const [location, setLocation] = useState("");
    const [name, setName] = useState(cookies.name);
    const [phone, setPhone] = useState(cookies.phone);
    const [email, setEmail] = useState(cookies.email);
    const [picture, setPicture] = useState(cookies.picture);
    const [totalHours, setTotalHours] = useState(0);
    const [totalHoursProcessed, setTotalHoursProcessed] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [showCheckoutDialog, setShowCheckoutDialog] = useState(window.location.pathname.includes("/checkout"));
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlertDialog, setShowAlertDialog] = useState(false)
    const [showAboutUsDialog, setShowAboutUsDialog] = useState(searchParams.get('about_us') !== null)
    const [showCancellationPolicyDialog, setShowCancellationPolicyDialog] = useState(searchParams.get('cancellation_policy') !== null)
    const [showContactUsDialog, setShowContactUsDialog] = useState(searchParams.get('contact_us') !== null)
    const [showPrivacyPolicyDialog, setShowPrivacyPolicyDialog] = useState(searchParams.get('privacy_policy') !== null)
    const [showTermsAndConditionsDialog, setShowTermsAndConditionsDialog] = useState(searchParams.get('terms_and_conditions') !== null)
    const [showProfileDialog, setShowProfileDialog] = useState(false)
    const [showContentCafeCustomizationDialog, setShowContentCafeCustomizationDialog] = useState(false)
    const [showPodcastCafeCustomizationDialog, setShowPodcastCafeCustomizationDialog] = useState(false)

    const [refreshList, setRefreshList] = useState(true);
    const [equipmentList, setEquipmentList] = useState<Equipment[]>([]);
    const [customizedEquipment, setCustomizedEquipment] = useState<Equipment>({
      id: "",
      name: "",
      type: "",
      description: "",
      price: "",
      quantity: 0,
      image: "",
      visibility: "",
      external: "",
      customization1: "",
      customization2: "",
    });
    const [selectedEquipments, setSelectedEquipments] = useState<Equipment[]>([]);

    const fetchData = (fetchedLocation :string) => {
        axios.post("/slate-pencil-data-ingest", {
            action: 'GET_EQUIPMENTS',
            location: fetchedLocation,
            start_date: startDate.toLocaleString("en-US"),
            end_date: endDate.toLocaleString("en-US"),
        }).then(response => {
            if (response.status === 200) {
                setShowAlertDialog(false);
                setAlertMessage("");

                var list : Equipment[] = [];
                for (let i = 0; i < response.data.length; i++) {
                    let data = response.data[i];
                    var equipment: Equipment = {
                        id: data[0],
                        name: data[1],
                        type: data[2],
                        description: data[3],
                        price: data[4],
                        quantity: data[5],
                        image: data[6],
                        visibility: data[7],
                        external: data[8],
                        customization1: data[9],
                        customization2: data[10],
                    }
                    list.push(equipment)
                }

                // Sort the list based on the type selection.
                list.sort((equipment) =>
                    (type) === equipment.type ? -1 : 1
                );

                setEquipmentList(list)
            } else {
                setShowAlertDialog(true);
                setAlertMessage("Failed to get collections!");
            }
        }).catch(error => {
            setShowAlertDialog(true);
            setAlertMessage("Failed to get collections!");
        });
    }

    useEffect(() => {
        if (navigator.geolocation && location === "") {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    var near = isNearest([latitude, longitude],
                        [13.077225, 80.270770], [11.009140, 76.967957])
                    setLocation(near ? "CHENNAI" : "COIMBATORE");
                    fetchData(near ? "CHENNAI" : "COIMBATORE");
                }
            );
        } else {
            if (location === "") {
                fetchData("COIMBATORE");
            } else {
                fetchData(location);
            }
        }
    }, [refreshList]);

    return (
        <GlobalContext.Provider value={
            {
                showLoader: showLoader,
                setShowLoader: setShowLoader,
                startDate: startDate,
                setStartDate: setStartDate,
                endDate: endDate,
                setEndDate: setEndDate,
                type: type,
                setType: setType,
                location: location,
                setLocation: setLocation,
                name: name,
                setName: setName,
                phone: phone,
                setPhone: setPhone,
                email: email,
                setEmail: setEmail,
                picture: picture,
                setPicture: setPicture,
                totalHours: totalHours,
                setTotalHours: setTotalHours,
                totalHoursProcessed: totalHoursProcessed,
                setTotalHoursProcessed: setTotalHoursProcessed,
                totalAmount: totalAmount,
                setTotalAmount: setTotalAmount,
                showCheckoutDialog: showCheckoutDialog,
                setShowCheckoutDialog: setShowCheckoutDialog,
                alertMessage: alertMessage,
                setAlertMessage: setAlertMessage,
                showAlertDialog: showAlertDialog,
                setShowAlertDialog: setShowAlertDialog,
                refreshList: refreshList,
                setRefreshList: setRefreshList,
                equipmentList: equipmentList,
                setEquipmentList: setEquipmentList,
                selectedEquipments: selectedEquipments,
                setSelectedEquipments: setSelectedEquipments,
                customizedEquipment: customizedEquipment,
                setCustomizedEquipment: setCustomizedEquipment,
                showAboutUsDialog: showAboutUsDialog,
                setShowAboutUsDialog: setShowAboutUsDialog,
                showCancellationPolicyDialog: showCancellationPolicyDialog,
                setShowCancellationPolicyDialog: setShowCancellationPolicyDialog,
                showContactUsDialog: showContactUsDialog,
                setShowContactUsDialog: setShowContactUsDialog,
                showPrivacyPolicyDialog: showPrivacyPolicyDialog,
                setShowPrivacyPolicyDialog: setShowPrivacyPolicyDialog,
                showTermsAndConditionsDialog: showTermsAndConditionsDialog,
                setShowTermsAndConditionsDialog: setShowTermsAndConditionsDialog,
                showProfileDialog: showProfileDialog,
                setShowProfileDialog: setShowProfileDialog,
                showContentCafeCustomizationDialog: showContentCafeCustomizationDialog,
                setShowContentCafeCustomizationDialog: setShowContentCafeCustomizationDialog,
                showPodcastCafeCustomizationDialog: showPodcastCafeCustomizationDialog,
                setShowPodcastCafeCustomizationDialog: setShowPodcastCafeCustomizationDialog,
            }}>
            <ThemeProvider theme={globalTheme}>
                <CssBaseline/>
                <Grid container component="main" sx={{
                    height: '100vh',
                    backgroundColor: 'primary.main'
                }}>
                    <Grid item xs={12} sm={12} md={4} lg={4} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        {/* Dialog box to get the start date, end date and type of equipment*/}
                        <GoogleOAuthProvider clientId="815639975877-ghndf3qrv06p15mgckbek02ocv993i2o.apps.googleusercontent.com">
                            <CookiesProvider>
                                <HomePageSearch/>
                            </CookiesProvider>
                        </GoogleOAuthProvider>
                    </Grid>
                    <Grid item xs={false} sm={false} md={8} lg={8} sx={{
                        backgroundImage: 'url(/camera-1.jpeg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                    }}/>
                </Grid>
                <EquipmentList/>
                <Footer/>
                <ProfileDialog />
                <CheckoutDialog/>
                <PaymentVerificationDialog/>
                <ContentCafeCustomizationDialog/>
                <PodcastCafeCustomizationDialog/>
                <AlertDialog />
                <CircularLoader />

                {/*DISPLAY THE CART BUTTON ONLY WHEN THERE ARE ITEMS IN THE CART*/}
                {
                    selectedEquipments.length !== 0 ? (
                        <Fab variant="extended" sx={{bottom: '1rem', right: '1rem', position: 'fixed', color: 'black'}} onClick={() =>{
                            setShowCheckoutDialog(true);
                            var totalTime = intervalToDuration({start: startDate, end: endDate});
                            var totalHours = 0;
                            var totalHoursProcessed = 0;
                            if (!totalTime) {
                                console.log("Error while calculating hours!");
                            } else {
                                if (totalTime.days) {
                                    totalHours += (totalTime.days * 24);
                                }
                                if (totalTime.hours) {
                                    totalHours += totalTime.hours;
                                }
                                if (totalTime.minutes) {
                                    totalHours += 1;
                                }

                                totalHoursProcessed = totalHours;

                                if (startDate != endDate) {
                                    if (totalTime.days) {
                                        totalHoursProcessed -= (totalTime.days * 12);
                                    } else if (totalTime.hours) {
                                        if (totalTime.hours > 12) {
                                            totalHoursProcessed -= 12;
                                        }
                                    }
                                }
                            }
                            setTotalHours(totalHours);
                            setTotalHoursProcessed(totalHoursProcessed);
                        }}>
                            <ShoppingCartIcon sx={{color: 'black', mr: 1}} />
                            Cart
                        </Fab>
                    ) : null
                }

            </ThemeProvider>
        </GlobalContext.Provider>
    );
}