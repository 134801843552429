import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List, ListItem, ListItemButton,
  SelectChangeEvent,
  Slide, Typography,
} from "@mui/material";
import React, { useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { useGlobalContext } from "../../context/GlobalContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Equipment from "../../data/Equipment";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PodcastCafeCustomizationDialog() {
  const globalContext = useGlobalContext();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [options1, setOptions1] = React.useState<String[]>([]);
  const [options2, setOptions2] = React.useState<String[]>([]);

  const validateDataAndAdd = () => {
    if (options1.length !== 1 || options2.length !== 1) {
      showAlert("Invalid selection!");
    } else {
      var customizedEquipment: Equipment = {
        ...globalContext.customizedEquipment,
      };
      customizedEquipment.customization1 = options1.toString();
      customizedEquipment.customization2 = options2.toString();
      addEquipment(customizedEquipment);
      globalContext.setShowPodcastCafeCustomizationDialog(false);
    }
  };

  const addEquipment = (customizedEquipment: Equipment) => {
    const equipments = globalContext.selectedEquipments;
    var equipmentCount = getEquipmentCount(equipments);
    // If the equipment is already present then increase the quantity.
    // If not present create new equipment with the quantity 1.
    if (equipmentCount === 0) {
      var newEquipment: Equipment = { ...customizedEquipment };
      newEquipment.quantity = 1;
      equipments.push(newEquipment);
      showAlert("Added to cart!");
    } else {
      // Increase only the quantity.
      var existingEquipment = getEquipment(equipments);
      if (globalContext.customizedEquipment.quantity > equipmentCount) {
        existingEquipment.quantity = Number(equipmentCount) + Number(1);
        showAlert("Added to cart!");
      } else {
        showAlert("Maximum limit reached!");
      }
    }
    globalContext.setSelectedEquipments(equipments);
    setCount(getEquipmentCount(equipments));
  };

  const getEquipmentCount = (equipments: Equipment[]) => {
    var count = 0;
    equipments.map((existingEquipment) => {
      if (globalContext.customizedEquipment.id === existingEquipment.id) {
        count = existingEquipment.quantity;
      }
    });
    return count;
  };

  const showAlert = (message: string) => {
    globalContext.setShowAlertDialog(true);
    globalContext.setAlertMessage(message);
  };

  const getEquipment = (equipments: Equipment[]) => {
    var selectedEquipment: Equipment = {
      id: "",
      name: "",
      type: "",
      description: "",
      price: "",
      quantity: 0,
      image: "",
      visibility: "",
      external: "",
      customization1: "",
      customization2: "",
    };
    equipments.map((existingEquipment) => {
      if (globalContext.customizedEquipment.id === existingEquipment.id) {
        selectedEquipment = existingEquipment;
      }
    });
    return selectedEquipment;
  };

  const [count, setCount] = useState(
    getEquipmentCount(globalContext.selectedEquipments)
  );

  return (
    <Dialog
      open={globalContext.showPodcastCafeCustomizationDialog}
      TransitionComponent={Transition}
      keepMounted
      fullScreen={fullScreen}
      onClose={() => globalContext.setShowPodcastCafeCustomizationDialog(false)}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: { borderRadius: fullScreen ? 0 : 15 },
      }}
    >
      <DialogTitle fontSize={30} fontWeight={800} sx={{ p: { xs: 2, md: 3 }, pb: { xs: 2, md: 1 } }}>
        Customize {globalContext.customizedEquipment.name}
      </DialogTitle>
      <DialogContent sx={{ pb: 0, px: { xs: 2, md: 2 } }}>
        <Typography px={2} variant='body2'>
          Select any 1
        </Typography>
        <List sx={{ width: '100%', pb: 0 }}>
          {globalContext.customizedEquipment.customization1.split(",").map((value) => {
            return (
                <ListItem
                    key={value}
                    onClick={() => {
                      if (!options1.includes(value)) {
                        setOptions1([...options1, value]);
                      } else {
                        setOptions1(options1.filter((item) => item !== value));
                      }
                    }}
                    secondaryAction={
                      <Checkbox
                          edge="end"
                          checked={options1.includes(value)}
                          onChange={() => {
                            if (!options1.includes(value)) {
                              setOptions1([...options1, value]);
                            } else {
                              setOptions1(options1.filter((item) => item !== value));
                            }
                          }}
                      />
                    }
                    disablePadding
                >
                  <ListItemButton>
                    <Typography variant="button">{value}</Typography>
                  </ListItemButton>
                </ListItem>
            );
          })}
        </List>
        <Typography px={2} variant='body2'>
          Select any 1
        </Typography>
        <List sx={{ width: '100%', pb: 0 }}>
          {globalContext.customizedEquipment.customization2.split(",").map((value) => {
            return (
                <ListItem
                    key={value}
                    onClick={() => {
                      if (!options2.includes(value)) {
                        setOptions2([...options2, value]);
                      } else {
                        setOptions2(options2.filter((item) => item !== value));
                      }
                    }}
                    secondaryAction={
                      <Checkbox
                          edge="end"
                          checked={options2.includes(value)}
                          onChange={() => {
                            if (!options2.includes(value)) {
                              setOptions2([...options2, value]);
                            } else {
                              setOptions2(options2.filter((item) => item !== value));
                            }
                          }}
                      />
                    }
                    disablePadding
                >
                  <ListItemButton>
                    <Typography variant="button">{value}</Typography>
                  </ListItemButton>
                </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          size={"large"}
          fullWidth
          variant="contained"
          sx={{ borderRadius: 2 }}
          onClick={() => {
            validateDataAndAdd();
          }}
        >
          Add to cart
        </Button>
        <Button
          size={"large"}
          fullWidth
          variant="contained"
          sx={{ borderRadius: 2 }}
          onClick={() => globalContext.setShowPodcastCafeCustomizationDialog(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
