import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from "@mui/material";
import React from "react";
import { TransitionProps } from "@mui/material/transitions";
import { useGlobalContext } from "../../context/GlobalContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TermsAndConditionsDialog() {
  const globalContext = useGlobalContext();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={globalContext.showTermsAndConditionsDialog}
      TransitionComponent={Transition}
      keepMounted
      fullScreen={fullScreen}
      onClose={() => globalContext.setShowTermsAndConditionsDialog(false)}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: { borderRadius: fullScreen ? 0 : 15, },
      }}
    >
      <DialogTitle fontSize={30} fontWeight={800} sx={{ p: { xs: 2, md: 3 } }}>
        Terms and Conditions
      </DialogTitle>
      <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
        <Typography>
          <b>1. INDEMNITIES</b>
          <br></br>
          &emsp;The Lessee shall indemnify, defend and hold harmless the Lessor
          from and against any and all claims, suits, proceedings, losses,
          damages, diminution in value, liabilities, and costs (including
          attorney’s fees) that arise out of or result from (a) any failure of
          the Lessee to comply with any of the provisions of the license(s);(b)
          any contravention by the Lessee of laws or regulations; or (c) the
          actual or alleged use, delivery or transportation of any of the Leased
          Equipment or its location or condition.
          <br></br>
          <br></br>
          <b>2. CONFIDENTIAL AND PROPRIETARY RIGHTS</b>
          <br></br>
          &emsp;“Confidential Information" shall mean and include any and all
          information disclosed by one Party to the other in writing, oral, or
          other tangible forms. The Parties agree to treat and maintain as
          confidential and proprietary all Confidential Information furnished by
          the Parties pursuant to or in connection with this Agreement. Both
          parties shall keep strictly confidential the terms of this Agreement
          including the financial terms and shall not disclose such information
          to any third parties without the prior written consent of the other
          Party.
          <br></br>
          <br></br>
          <b>3. RIGHTS AND OBLIGATIONS</b>
          <br></br>
          &emsp;<b>3.1</b> - All of the Leased Equipment is provided in good,
          working condition. The sole remedy to the Lessee for breach of the
          foregoing warranties is the repair or correction by the Lessor of such
          non-conformity which repair shall be shared on mutually agreed terms.
          The Lessor shall if any problem arises take back such equipment if
          such defect has been found during the course of the lease period by
          the Lessee. Except as expressly provided herein, the Lessor makes no
          representation or warranty, either express or implied, as to the
          fitness, design, or condition of, or as to the quality or capacity of
          the material, equipment, purpose, or workmanship of the Leased
          Equipment, nor any warranty that the Leased Equipment will satisfy the
          requirements of any law, rule, specification or contract, it is agreed
          that all such risks as between the Lessor and the Lessee are to be
          borne by the Lessee at Lessee’s sole risk and expense. By accepting
          the Leased Equipment, the Lessee shall be deemed to have examined the
          same and the Lessee shall not at any time have a claim against the
          Lessor. The Lessee agrees that, except as expressly provided in this
          section, the Lessor shall have no liabilities or obligations under
          warranty to the Lessee for damages arising out of in connection with
          the delivery, use, or performance of the leased equipment.
          <br></br>
          <br></br>
          &emsp;<b>3.2</b> - Notwithstanding any other provision of this
          Agreement and irrespective of any fault or negligence, the Lessor
          shall not be liable to the other for any indirect, reliance, special,
          punitive, consequential, exemplary, or incidental damages (including
          without limitation damages for harm to business, lost revenues, lost
          sales, lost savings, lost profits (anticipated or actual), loss of
          use, and claims of third parties), regardless of the form of action,
          whether in contract, warranty, strict liability or tort or any other
          legal or equitable theory arising out of or in connection with this
          Agreement, even if a party has been advised of the possibility of such
          damages. In no event shall the Lessor be liable for any damages
          arising out of or in connection with this Agreement or the Services in
          excess of the amounts paid by the Lessee during a period of 12 months
          preceding the date of the Lessee's written notice by which the Lessee
          informs the Lessor of having suffered any such damage.
          <br></br>
          <br></br>
          &emsp;<b>3.3</b> - The contents of the Lessor’s website and the
          equipment the Lessor delivers are provided in good, working condition.
          The Lessor makes no representations or warranties, whether express or
          implied, of any kind about equipment’s accuracy or functionality. The
          Lessor assumes no liability or responsibility for any errors or
          omissions or changes in the content of their website, for any
          failures, delays, or interruptions in the delivery of any content
          contained on their website, or for any losses or damages arising from
          the use of the content provided on their website, or for any conduct
          by users of their website. To the full extent permissible by law, the
          Lessor disclaims all representations and warranties about the
          equipment the Lessor delivers, including, for example, warranties of
          merchantability, fitness for a particular purpose, and
          non-infringement. In no event shall the Lessor be liable to the Lessee
          for any special, incidental, indirect, or consequential damages of any
          kind, or any damages resulting from loss of use, data, or profits,
          whether or not advised of the possibility of damage, and on any theory
          of liability, arising out of or in connection with the use or
          performance of any equipment or the information on the Lessor’s
          website.
          <br></br>
          <br></br>
          &emsp;<b>3.4</b> - The Lessor will lease charges the leased equipment
          as and when it becomes available. There may be conditions and
          situations when the equipment the Lessee has ordered is out of stock.
          The Lessor makes no guarantees as to the availability of equipment or
          timely delivery of such equipment. The Lessor will keep the Lessee
          informed of any products that the Lessee has ordered that are
          out-of-stock and unavailable for immediate shipment. If the equipment
          is out-of-stock or unavailable, the Lessee may cancel the order at any
          time prior to shipping.
          <br></br>
          <br></br>
          &emsp;<b>3.5</b> - The receipt of electronic or other forms of order
          confirmation by the Lessee does not signify the Lessor’s acceptance of
          the Lessee’s order, nor does it constitute confirmation of the
          Lessor’s offer to lease charges. The Lessor reserves the right at any
          time after receipt of the Lessee’s order to accept or decline the
          Lessee’s order or to supply equipment quantity lesser than that
          ordered by the Lessee.
          <br></br>
          <br></br>
          &emsp;<b>3.6</b> - The Lessor and the Lessee shall both maintain a
          Logbook which will be used to record the date of receipt and the date
          of the return for every recurring lease interval including but not
          limited to an electronic log through the web or otherwise during the
          course of the lease agreement for each of the equipment mentioned in
          Annexure ‘A’.
          <br></br>
          <br></br>
          &emsp;<b>3.7</b> - The Lessee will allow the Lessor to stick tags,
          stickers, nameplates, and logos on the equipment indicating ownership
          of the Lessor.
          <br></br>
          <br></br>
          &emsp;<b>3.8</b> - If the Lessor shall so request, Lessee shall
          execute and deliver to the Lessor such documents as the Lessor shall
          deem necessary or desirable for purposes of recording or filing to
          protect the interest of the Lessor in the leased equipment including,
          but not limited to a UCC financing statement.
          <br></br>
          <br></br>
          <b>4. TERMINATION AND EFFECT OF TERMINATION</b>
          <br></br>
          &emsp;<b>4.1</b> - This Agreement shall come into force on the date of
          execution mentioned herein and shall continue and remain in effect
          until the Term; provided, however, that (a) the Lessor may terminate
          the agreement and this Agreement immediately upon written notice of
          termination following a default or breach by the Lessee in making any
          payment required here under and/or any other default of any other
          material obligation under this Agreement and the failure of the Lessee
          to cure such default or breach within 15 calendar days after receiving
          written notice thereof; or (b) the Lessee may terminate this Agreement
          by giving a 30 days notice and by paying the outstanding dues if any
          along with the amount of future lease charges payables till the end of
          the Lease Term.
          <br></br>
          <br></br>
          &emsp;<b>4.2</b> - Upon termination of the Term or this Agreement for
          any reason whatsoever, the Lessee shall allow the Lessor / Lessor’s
          representatives to take the Equipment from the Lessee’s premises in
          good repair, order, and condition (subject to normal wear and tear).
          Upon termination of the Term or this Agreement, If the Lessee fails to
          observe, keep or perform any other provision of this agreement, the
          Lessor may treat the Lessee in default and (a) recover from the Lessee
          all amounts then due and to become due under this Agreement, such
          amount at the Lessor’s option to be payable forthwith; (b) take
          possession of all of the Equipment wherever the same may be located
          and in this regard, the Lessor and/or its agents may take such steps
          as it considers necessary or desirable to obtain possession of the
          Equipment including rendering the Equipment unusable or without
          notice, liability or legal process enter into any place where the
          Equipment may be or believed by the Lessor to be, and repossess the
          Equipment, disconnecting and separating it from any other property and
          using all force necessary therefore, and the Lessee hereby expressly
          waives all further rights to possession of the Equipment following any
          such repossession; and (c) pursue any other remedy available at law or
          in equity, including without limitation, seeking damages.
          <br></br>
          <br></br>
          &emsp;<b>4.3</b> - In the event of cancellation during or before the
          Lease term, cancellation charges may apply in consideration of the
          Lessor’s preparing, holding in reserve, or blocking the equipment on
          the Lessee’s behalf. The Lessor shall be entitled to compensation, not
          to exceed the lease payments, for any losses the Lessor may sustain
          because of the cancellation of all or part of an order. The Lessor may
          also withhold an appropriate part of the Security Deposit in the event
          of premature termination by the Lessee.
          <br></br>
          <br></br>
          &emsp;<b>4.4</b> - In the event of the Lessee not returning the Leased
          Equipment to the Lessor within a maximum of 15 days of termination of
          the agreement, the Security Deposit will not be refunded and an
          additional charge of 24% per annum on the lease charges amount till
          the date of return will be levied on the Lessee and the same will have
          to be paid to the Lessor.
          <br></br>
          <br></br>
          <b>5. TITLE, USE, AND LOSS OF EQUIPMENT</b>
          <br></br>
          &emsp;<b>5.1</b> - All the Equipment shall remain personal property
          and be deemed as property of the Lessor or its owners as the case may
          be, whether it has been affixed to realty or otherwise, and shall
          remain subject to the rights of the Lessor as fully as before being so
          affixed, and title thereto or property therein shall pass to the
          Lessee upon terms agreed. The Lessee shall at no time contest or
          challenge the Lessor's sole and exclusive ownership right, title, and
          interest in the Equipment. The Lessee shall be responsible for the
          safe keeping of the Equipment as the Lessee would do for other similar
          equipment on its premises, including but not limited to the
          maintenance of the minimum site environmental conditions for the safe
          working of the Equipment. The Lessee hereby further undertakes that it
          shall not approach any third party other than supplier/authorized
          Lessor for upkeep, repairs, and maintenance of the Equipment under
          this agreement. Failure to observe this condition will be considered
          an event of default. The Lessee shall keep the Equipment free from any
          and all liens, claims or other encumbrances including any judicial
          process affecting the Equipment whatsoever, and shall do or permit no
          act or thing whereby the Company’s title or rights may be encumbered
          or impaired and shall indemnify the Company for any loss caused
          thereby.
          <br></br>
          <br></br>
          &emsp;<b>5.2</b> - The Lessee must: Notify the Lessor in writing if
          any Equipment becomes lost, stolen, damaged, destroyed, or otherwise
          unfit or unavailable for use from any cause (an Event of Loss);
          Provide the Lessor with all necessary documentation relating to such
          loss or damage Assist the Lessor or his agents in the recovery of the
          equipment or may equate for Insurance Companies and other concerned
          parties
          <br></br>
          <br></br>
          &emsp;<b>5.3</b> - The Lessee hereby assumes and shall bear the entire
          risk of loss and damage to the Equipment from any and every cause. No
          loss or damage to the Equipment or any part thereof shall impair any
          obligation of Lessee under this Lease which shall continue in full
          force and effect through the term of the Lease.
          <br></br>
          <br></br>
          &emsp;<b>5.4</b> - In the event of damage of any kind to the
          Equipment, the Lessee agrees to pay for any repair that is to be
          undertaken to the equipment in order to enable it to run in a good and
          working condition. The Lessor shall decide the venue and place of
          repair and the Lessee shall adhere to the same. The Lessee will also
          allow the Lessor to charge Lessee's credit card for loss of lease
          charges in the event a damage waiver is not availed on the order.
          <br></br>
          <br></br>
          &emsp;<b>5.5</b> - In the event of leased equipment being damaged
          beyond repair, the Lessee shall undertake to pay for a full
          replacement and shall not be entitled to the ownership of the damaged
          Equipment.
          <br></br>
          <br></br>
          &emsp;<b>5.6</b> - In the event of stickers attached to leased
          equipment becoming damaged or lost, Lessees shall pay the full
          replacement fee of 50 Rs. per sticker per piece of the leased
          equipment.
          <br></br>
          <br></br>
          &emsp;<b>5.7</b> - The Lessee will be allowed to use the said
          equipment only for purposes decided to by the parties i.e. either for
          his/her own personal use or to sub-lease all or part of the equipment
          to other Lessees at the rates decided upon by the parties.
          <br></br>
          <br></br>
          <b>6. DAMAGE WAIVER</b>
          <br></br>
          &emsp;<b>6.1</b> - The Lessor offers Lessee the option to avail of a
          ‘damage waiver’ for unintentional damage to the Equipment during the
          Lease charges Period. The determination of whether the damage is
          unintentional and not abuse is at the sole discretion of the Lessor.
          In the event of the damage covered by a damage waiver, the Lessee will
          pay the Lessor a deductible amounting to 50% of the value of a new
          item as the Equipment lease leased to the Lessee. The valuation of the
          Equipment is within the sole discretion of the Lessor.
          <br></br>
          <br></br>
          &emsp;<b>6.2</b> - A damage waiver does not cover: Lost or stolen
          items Water damage Any peripheral items including lens hoods, battery
          chargers, front and rear caps, cables, others, etc. If the Lessee
          loses and/or damages these items, the Lessee will need to pay for such
          peripheral items to be replaced even if the Lessee avails a damage
          waiver.
          <br></br>
          <br></br>
          <b>7. TAXES</b>
          <br></br>
          &emsp;Service Tax at the prevailing rate will be levied for the lease
          of the equipment. Any other tax/levy/duty that may be applicable may
          also be levied upon the leased equipment. The Lessee shall pay and
          hereby indemnify and holds Lessor harmless from all fees, assessments,
          taxes, octroi, and other duties and charges imposed by any
          governmental body or agency upon or with respect to any equipment, or
          the possession, ownership, use, or operation thereof.
          <br></br>
          <br></br>
          <b>8. DISPUTE RESOLUTION & ARBITRATION</b>
          <br></br>
          &emsp;<b>8.1</b> - Any dispute arising out of or relating to this
          Agreement whether during its term or after expiry thereof or prior
          termination will be first attempted to be resolved through mutual
          discussions and conciliation between the Parties. If the Parties are
          unable to resolve the dispute within thirty (30) days through
          conciliation, then all disputes and differences of whatsoever nature
          arriving out of this Agreement will be settled by arbitration in
          accordance with the Arbitration and Conciliation Act 1996 and any
          statutory amendment thereof. The dispute will be referred to the sole
          arbitrator who will be appointed by the Lessor. The venue of the
          arbitration will be Coimbatore. The decision of the arbitrator will be
          final, conclusive, and binding on the Parties. Notwithstanding the
          foregoing, however, nothing contained herein will be deemed to prevent
          either Party from seeking and obtaining injunctive and equitable
          relief from any court of competent jurisdiction without the posting of
          any bond or other security.
          <br></br>
          <br></br>
          &emsp;<b>8.2</b> - The Parties agree that this Agreement and all
          rights and obligations of the Parties here under shall be governed,
          construed, and interpreted exclusively under the laws of India and
          both parties shall ensure the compliance of all applicable laws.
          <br></br>
          <br></br>
          &emsp;<b>8.3</b> - Each Party acknowledges that it has read this
          Agreement, understands it, and agrees to be bound by its terms and
          further agrees that this Agreement, together with the Terms and
          Conditions and Annexure attached hereto as of the date hereof is the
          complete statement of the agreement between the Parties. This
          Agreement may not be modified except by a written instrument duly
          executed by both Parties.
          <br></br>
          <br></br>
          &emsp;<b>8.4</b> - If any provision of this Agreement shall be held to
          be invalid, illegal, or unenforceable, the validity, legality, and
          enforceability of the remaining provisions shall in no way be affected
          or impaired thereby and shall continue to remain in full force and
          effect. The failure or delay of either Party to exercise in any
          respect any right provided for herein shall not be deemed a waiver of
          any right here under.
          <br></br>
          <br></br>
          &emsp;<b>8.5</b> - This Agreement does not make either Party the
          employee, agent, or legal representatives of the other for any purpose
          whatsoever.
          <br></br>
          <br></br>
          <b>9. Booking policy</b>
          <br></br>
          &emsp;<b>9.1</b> - An advance amount of 100% or 50% is to be paid for
          blocking the equipment and the payment is to be made through the
          website only.
          <br></br>
          <br></br>
          &emsp;<b>9.2</b> - The Lessee has to give a xerox copy of the Aadhar
          card and cheque with the value of the equipment being rented in the
          name of SM Media LLP. which will be returned to the lessee while
          returning the equipment. If there is any delay in returning the
          equipment the cheque will proceed for collection in the bank. if the
          cheque gets returned, the lessee will face the consequences of cheque
          return as per laws.
          <br></br>
          <br></br>
          <b>10. Cancellation Policy</b>
          <br></br>
          &emsp;<b>10.1</b> - If there is any cancellation, it has to be
          informed and to be canceled before 24 hours from the booking start. If
          it is canceled before 24 hours only 75% advance will be refunded. If
          it is canceled within 24 hours from the booking start the booking
          amount will not be refunded for any cause.
        </Typography>
        <br></br>
        <Typography variant={"h6"}>Contact Details</Typography>
        <Typography>
          <li>
            <b>Phone:</b>+91 93612 71285
          </li>
          <li>
            <b>Email:</b>signaturemomentsllp@gmail.com
          </li>
          <li>
            <b>Whatsapp:</b>+91 93612 71285
          </li>
          <li>
            <b>Instagram:</b>slatepencil_sm
          </li>
          <li>
            <b>Address:</b>16A New No: 26B Kongu Nagar, Ramanathapuram,
            Coimbatore - 641005.
          </li>
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          size={"large"}
          fullWidth
          variant="contained"
          sx={{ borderRadius: 2 }}
          onClick={() => globalContext.setShowTermsAndConditionsDialog(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
