import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Slide,
    Skeleton,
    Typography, Divider, IconButton
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useSearchParams } from "react-router-dom";
import {TransitionProps} from "@mui/material/transitions";
import axios from "axios";
import ErrorIcon from '@mui/icons-material/Error';
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import html2canvas from 'html2canvas';
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function saveDetails() {
    setTimeout(function () {
        var dialog = document.getElementById('p-v-d');
        if (dialog) {
            html2canvas(dialog).then(function(canvas) {
                var link = document.createElement('a');
                link.download = 'sm-rental-booking.png';
                link.href = canvas.toDataURL()
                link.click();
            });
        }
    }, 500);
}

export default function PaymentVerificationDialog() {
    const [searchParams, setSearchParams] = useSearchParams();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [display, setDisplay] = useState(searchParams.get('razorpay_payment_id') !== null);
    const [status, setStatus] = useState("Processing...");
    const [disableButton, setDisableButton] = useState(true);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [equipments, setEquipments] = useState("");
    const [bookingStart, setBookingStart] = useState("");
    const [bookingEnd, setBookingEnd] = useState("");
    const [amount, setAmount] = useState("");
    const [razorpayPaymentId, setRazorpayPaymentId] = useState(searchParams.get('razorpay_payment_id'));
    const [razorpayPaymentLinkId] = useState(searchParams.get('razorpay_payment_link_id'));
    const [razorpayPaymentLinkReferenceId] = useState(searchParams.get('razorpay_payment_link_reference_id'));
    const [razorpayPaymentLinkStatus] = useState(searchParams.get('razorpay_payment_link_status'));
    const [razorpaySignature] = useState(searchParams.get('razorpay_signature'));

    useEffect(() => {
        if (display) {
            axios.post("/slate-pencil-data-ingest", {
                action: 'VERIFY_PAYMENT',
                razorpayPaymentId: razorpayPaymentId,
                razorpayPaymentLinkId: razorpayPaymentLinkId,
                razorpayPaymentLinkReferenceId: razorpayPaymentLinkReferenceId,
                razorpayPaymentLinkStatus: razorpayPaymentLinkStatus,
                razorpaySignature: razorpaySignature
            }).then(response => {
                if (response.status === 201) {
                    // Change the status.
                    setStatus('Booking Details');

                    // Set the booking fields.
                    setName(response.data['name']);
                    setEmail(response.data['email']);
                    setPhone(response.data['phone']);
                    setEquipments(response.data['equipments']);
                    setBookingStart(response.data['bookingStart']);
                    setBookingEnd(response.data['bookingEnd']);
                    setAmount(response.data['amount']);
                    setRazorpayPaymentId(response.data['paymentId']);

                    // Enable the close button.
                    setDisableButton(false);
                } else {
                    setStatus('Payment failed!');

                    // Enable the close button.
                    setDisableButton(false);
                }

                saveDetails();
            }).catch(error => {
                setStatus('Payment failed!');

                // Enable the close button.
                setDisableButton(false);

                saveDetails();
            });

            // Removing the sensitive data from the url.
            searchParams.delete('razorpay_payment_id');
            searchParams.delete('razorpay_payment_link_id');
            searchParams.delete('razorpay_payment_link_reference_id');
            searchParams.delete('razorpay_payment_link_status');
            searchParams.delete('razorpay_signature');
            setSearchParams(searchParams);
        }
    },[])

    return(
        <Dialog
            id={'p-v-d'}
            open={display}
            TransitionComponent={Transition}
            keepMounted
            fullScreen={fullScreen}
            onClose={(event, reason) => {
                if (reason && reason === "backdropClick") {
                    return;
                }

                setDisplay(false)
            }}
            PaperProps={{
                style: {borderRadius: (fullScreen ? 0 : 15)}
            }}
        >
            <DialogTitle fontSize={30} fontWeight={800} sx={{p: {xs: 2, md: 3}}}>
                {status}
                {
                    fullScreen ? (
                        <IconButton sx={{color: 'black', float: 'right', pt: 1.5}} onClick={() => setDisplay(false)}>
                            <CloseIcon/>
                        </IconButton>
                    ) : null
                }
            </DialogTitle>
            <DialogContent sx={{p: {xs: 2, md: 3}}}>
                {
                    status === 'Processing...' ? (
                        <Grid sx={{textAlign: 'center', p: 2}}>
                            <Grid item>
                                <CircularProgress color="inherit"/>
                            </Grid>
                            <Grid item sx={{pt: 4, pb: 4}}>
                                <Typography variant={'body1'}>
                                    Please wait while we process your transaction.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Skeleton animation="wave" height={40}/>
                            </Grid>
                            <Grid item>
                                <Skeleton animation="wave" height={40}/>
                            </Grid>
                            <Grid item>
                                <Skeleton animation="wave" height={40}/>
                            </Grid>
                        </Grid>
                    ): status === 'Booking Details' ? (
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <Typography sx={{fontSize: {xs: '1rem', md: '1.2rem'}, fontWeight: 'bold'}}>
                                    Name
                                </Typography>
                            </Grid>
                            <Grid item xs={7} sx={{textAlign: 'center', wordBreak: 'break-all', alignSelf: 'center'}}>
                                <Typography>
                                    {name}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography sx={{fontSize: {xs: '1rem', md: '1.2rem'}, fontWeight: 'bold'}}>
                                    Email
                                </Typography>
                            </Grid>
                            <Grid item xs={7} sx={{textAlign: 'center', wordBreak: 'break-all', alignSelf: 'center'}}>
                                <Typography>
                                    {email}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography sx={{fontSize: {xs: '1rem', md: '1.2rem'}, fontWeight: 'bold'}}>
                                    Phone
                                </Typography>
                            </Grid>
                            <Grid item xs={7} sx={{textAlign: 'center', wordBreak: 'break-all', alignSelf: 'center'}}>
                                <Typography>
                                    {phone}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography sx={{fontSize: {xs: '1rem', md: '1.2rem'}, fontWeight: 'bold'}}>
                                    Equipments
                                </Typography>
                            </Grid>
                            <Grid item xs={7} sx={{textAlign: 'center', wordBreak: 'break-all', alignSelf: 'center'}}>
                                <Typography>
                                    {equipments}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography sx={{fontSize: {xs: '1rem', md: '1.2rem'}, fontWeight: 'bold'}}>
                                    Booking Start
                                </Typography>
                            </Grid>
                            <Grid item xs={7} sx={{textAlign: 'center', wordBreak: 'break-all', alignSelf: 'center'}}>
                                <Typography>
                                    {bookingStart}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography sx={{fontSize: {xs: '1rem', md: '1.2rem'}, fontWeight: 'bold'}}>
                                    Booking End
                                </Typography>
                            </Grid>
                            <Grid item xs={7} sx={{textAlign: 'center', wordBreak: 'break-all', alignSelf: 'center'}}>
                                <Typography>
                                    {bookingEnd}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography sx={{fontSize: {xs: '1rem', md: '1.2rem'}, fontWeight: 'bold'}}>
                                    Amount
                                </Typography>
                            </Grid>
                            <Grid item xs={7} sx={{textAlign: 'center', wordBreak: 'break-all', alignSelf: 'center'}}>
                                <Typography>
                                    {"₹ " + amount.toLocaleString()}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography sx={{fontSize: {xs: '1rem', md: '1.2rem'}, fontWeight: 'bold'}}>
                                    Payment ID
                                </Typography>
                            </Grid>
                            <Grid item xs={7} sx={{textAlign: 'center', wordBreak: 'break-all', alignSelf: 'center'}}>
                                <Typography>
                                    {razorpayPaymentId}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{textAlign: 'center', pt: 8}}>
                                <Button size={'small'} variant="outlined" onClick={() => saveDetails()}>
                                    Save
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={'body1'} sx={{pt: 1}}>
                                    Thank you for renting our equipment. Our team will contact you to collect the required
                                    documents such as your Identity Proofs(Passort/Driving Licence) and a security deposit
                                    cheque in favour of 'SM MEDIA LLP'. If you have any queries feel free to contact us.
                                </Typography>
                            </Grid>
                        </Grid>

                    ) : status === 'Payment failed!' ? (
                        <Grid container>
                            <Grid item xs={12} sx={{p: 2, textAlign: 'center'}}>
                                <ErrorIcon fontSize={'large'}/>
                            </Grid>
                            <Grid item>
                                <DialogContentText>
                                    Something went wrong. We were unable to process your transaction at this time.
                                    Please try again or if
                                    this problem persits, please contact us with the payment details below for further
                                    assistance. Rest assured, your money is safe!
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{pt: 2}}/>
                            </Grid>
                            <Grid item xs={12} sx={{pt: 2, pb: 2}}>
                                <Typography sx={{fontWeight: 'bold', fontSize: '1.2rem'}}>
                                    Payment Details
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant={'button'}>
                                    Payment ID
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sx={{textAlign: 'center'}}>
                                <Typography variant={'subtitle1'}>
                                    {razorpayPaymentId}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant={'button'}>
                                    Payment Link ID
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sx={{textAlign: 'center'}}>
                                <Typography variant={'subtitle1'}>
                                    {razorpayPaymentLinkId}
                                </Typography>
                            </Grid>
                        </Grid>

                    ) : null
                }
            </DialogContent>
            <DialogActions sx={{p: 3}}>
                <Button size={'large'} fullWidth variant="contained"
                        onClick={() => setDisplay(false)}
                        sx={{
                            borderRadius: 2
                        }}
                        disabled={disableButton}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}