import {
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Skeleton,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { useGlobalContext } from "../../context/GlobalContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { googleLogout } from "@react-oauth/google";
import { useCookies } from "react-cookie";
import Booking from "../../data/Booking";
import axios from "axios";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProfileDialog() {
  const globalContext = useGlobalContext();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [page, setPage] = useState(0);
  const [bookings, setBookings] = useState<Booking[]>([]);

  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const [cookies, setCookie, removeCookie] = useCookies([
    "name",
    "phone",
    "email",
    "picture",
  ]);

  useEffect(() => {
    if (globalContext.email !== undefined) {
      setBookings([]);
      axios
        .post("/slate-pencil-data-ingest", {
          action: "GET_BOOKINGS",
          email: globalContext.email,
          page: 0,
          size: 100,
        })
        .then((response) => {
          if (response.status === 200) {
            var list: Booking[] = [];
            for (let i = 0; i < response.data.length; i++) {
              let data = response.data[i];
              var booking: Booking = {
                from: data["bookingStart"],
                to: data["bookingEnd"],
                amount: data["amount"],
                paidAmount: data["amountPaid"],
                equipment: data["equipments"],
                paymentLink: data["paymentLink"],
              };
              list.push(booking);
            }

            setBookings(list);

            if (list.length === 0) {
              setMessage("Ouhh...it's empty in here.");
              setShowMessage(true);
            }
          } else {
            setMessage("Oops...something went wrong.");
            setShowMessage(true);
          }
        })
        .catch((error) => {
          setMessage("Oops...something went wrong.");
          setShowMessage(true);
        });
    }
  }, [globalContext.showProfileDialog]);

  return (
    <Dialog
      open={globalContext.showProfileDialog}
      TransitionComponent={Transition}
      keepMounted
      fullScreen={fullScreen}
      onClose={() => globalContext.setShowProfileDialog(false)}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          borderRadius: fullScreen ? 0 : 15,
          width: fullScreen ? "100%" : "60%",
          maxWidth: fullScreen ? "100%" : "500px",
        },
      }}
    >
      <DialogContent sx={{ p: { xs: 2, md: 3 }, textAlign: "center" }}>
        <Box display={"flex"} justifyContent={"center"} p={2}>
          <Avatar
            alt={globalContext.name}
            src={globalContext.picture}
            sx={{ width: 96, height: 96 }}
          />
        </Box>
        <Typography variant="h5" component="div" fontWeight={"bold"}>
          {globalContext.name}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontSize: 14 }}
        >
          {globalContext.email}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontSize: 14 }}
        >
          {globalContext.phone}
        </Typography>
        <Button
          variant="outlined"
          size="small"
          sx={{ m: 1 }}
          onClick={() => {
            globalContext.setName("");
            globalContext.setEmail("");
            globalContext.setPhone("");
            globalContext.setPicture("");
            removeCookie("name");
            removeCookie("phone");
            removeCookie("email");
            removeCookie("picture");
            globalContext.setShowProfileDialog(false);
            googleLogout();
          }}
        >
          Logout
        </Button>

        <Typography
          variant="h4"
          textAlign={"center"}
          fontWeight={"bold"}
          py={2}
        >
          Bookings
        </Typography>
        <Stack spacing={2}>
          {bookings.length === 0 && !showMessage ? (
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Skeleton sx={{ bgcolor: "gray" }} height={100} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton sx={{ bgcolor: "gray" }} height={100} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton sx={{ bgcolor: "gray" }} height={100} />
              </Grid>
            </Grid>
          ) : null}

          {showMessage ? (
            <Typography variant="subtitle1">{message}</Typography>
          ) : null}

          {bookings.map((booking) => (
            <Slide direction="up" in={true} key={Math.random() * 100}>
              <Card variant="outlined">
                <Grid container textAlign={"center"} p={1}>
                  <Grid item xs={5} alignSelf={"center"}>
                    <Typography variant="subtitle1" fontWeight={"bold"}>
                      {booking.from.split(" ")[0]}
                      <br></br>
                      {booking.from.toUpperCase().split(" ")[1] +
                        " " +
                        booking.from.toUpperCase().split(" ")[2]}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sx={{ alignSelf: "center" }}>
                    <Typography variant="subtitle1" fontWeight={"bold"}>
                      →
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="subtitle1" fontWeight={"bold"}>
                      {booking.to.split(" ")[0]}
                      <br></br>
                      {booking.to.toUpperCase().split(" ")[1] +
                        " " +
                        booking.to.toUpperCase().split(" ")[2]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" fontWeight={"bold"}>
                      ₹ {booking.amount.toLocaleString()}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} pt={1}>
                    <Typography variant="body2">
                      {booking.equipment.split("]")[1]}
                    </Typography>
                  </Grid>
                  {booking.paidAmount !== booking.amount ? (
                    <Grid item xs={12} py={1}>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          window.open(booking.paymentLink, "_blank");
                        }}
                      >
                        Pay Remaining ₹{" "}
                        {(booking.amount - booking.paidAmount).toLocaleString()}
                      </Button>
                    </Grid>
                  ) : null}
                </Grid>
              </Card>
            </Slide>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          size={"large"}
          fullWidth
          variant="contained"
          sx={{ borderRadius: 2 }}
          onClick={() => globalContext.setShowProfileDialog(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
