import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from "@mui/material";
import React from "react";
import { TransitionProps } from "@mui/material/transitions";
import { useGlobalContext } from "../../context/GlobalContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ContactUsDialog() {
  const globalContext = useGlobalContext();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={globalContext.showContactUsDialog}
      TransitionComponent={Transition}
      keepMounted
      fullScreen={fullScreen}
      onClose={() => globalContext.setShowContactUsDialog(false)}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: { borderRadius: fullScreen ? 0 : 15, },
      }}
    >
      <DialogTitle fontSize={30} fontWeight={800} sx={{ p: { xs: 2, md: 3 } }}>
        Contact Us
      </DialogTitle>
      <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
        <Typography>
          <b>Slate Pencil</b> is a premium media equipment rental company
          located in the city of <b>Coimbatore</b>. We rent media gear like{" "}
          <b>Cameras, Lenses, Lights, and Accessories</b>. We provide media
          equipment for rentals at affordable prices. Enterprise renting is also
          supported based on gear availability.
          <br></br>
          <br></br>
          We are open from <b>9 AM</b> to <b>9 PM</b> at{" "}
          <b>
            16A New No: 26B Kongu Nagar, Ramanathapuram, Coimbatore - 641005.
          </b>
          <br></br>
          <br></br>
        </Typography>
        <Typography variant={"h6"}>Contact Details</Typography>
        <Typography>
          <li>
            <b>Phone:</b>+91 93612 71285
          </li>
          <li>
            <b>Email:</b>signaturemomentsllp@gmail.com
          </li>
          <li>
            <b>Whatsapp:</b>+91 93612 71285
          </li>
          <li>
            <b>Instagram:</b>slatepencil_sm
          </li>
          <li>
            <b>Address:</b>16A New No: 26B Kongu Nagar, Ramanathapuram,
            Coimbatore - 641005.
          </li>
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          size={"large"}
          fullWidth
          variant="contained"
          sx={{ borderRadius: 2 }}
          onClick={() => globalContext.setShowContactUsDialog(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
