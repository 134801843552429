import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import axios from 'axios';
import Home from "./pages/Home";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import axiosRetry from 'axios-retry';

axios.defaults.baseURL = "https://us-central1-habiot.cloudfunctions.net/"
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

axiosRetry(axios, { retries: 3 });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path="/*" element={<Home/>} />
          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);
