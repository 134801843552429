import {Snackbar, SnackbarContent} from "@mui/material";
import React from "react";
import {useGlobalContext} from "../../context/GlobalContext";

export default function AlertDialog() {
    const globalContext = useGlobalContext();

    return (
        <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} autoHideDuration={2500}
                  open={globalContext.showAlertDialog} onClose={() => globalContext.setShowAlertDialog(false)}>
            <SnackbarContent message={globalContext.alertMessage} sx={{
                borderRadius: 3,
                color: 'primary.main',
                justifyContent: 'center',
                backgroundColor: 'primary.light',
            }}/>
        </Snackbar>
    );
}