import {Backdrop, CircularProgress} from "@mui/material";
import React from "react";
import {useGlobalContext} from "../../context/GlobalContext";

export default function CircularLoader() {
    const globalContext = useGlobalContext();

    const closeLoader = () => {
        globalContext.setShowLoader(false);
    }

    return(
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={globalContext.showLoader}
            onClick={closeLoader}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}