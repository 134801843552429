import {
  Button,
  ButtonGroup,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useGlobalContext } from "../context/GlobalContext";
import Equipment from "../data/Equipment";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { BrowserView, MobileView } from "react-device-detect";

const dataBoxStyle = {
  paddingLeft: "1rem",
  paddingBottom: "0rem",
  marginTop: "0.5rem",
  height: "75px",
  overflow: "scroll",
};

export default function EquipmentCard(equipment: Equipment) {
  const globalContext = useGlobalContext();

  const showAlert = (message: string) => {
    globalContext.setShowAlertDialog(true);
    globalContext.setAlertMessage(message);
  };

  const getEquipmentCount = (equipments: Equipment[]) => {
    var count = 0;
    equipments.map((existingEquipment) => {
      if (equipment.id === existingEquipment.id) {
        count = existingEquipment.quantity;
      }
    });
    return count;
  };

  const getEquipment = (equipments: Equipment[]) => {
    var selectedEquipment: Equipment = {
      id: "",
      name: "",
      type: "",
      description: "",
      price: "",
      quantity: 0,
      image: "",
      visibility: "",
      external: "",
      customization1: "",
      customization2: "",
    };
    equipments.map((existingEquipment) => {
      if (equipment.id === existingEquipment.id) {
        selectedEquipment = existingEquipment;
      }
    });
    return selectedEquipment;
  };

  const addEquipment = () => {
    const equipments = globalContext.selectedEquipments;
    var equipmentCount = getEquipmentCount(equipments);
    // If the equipment is already present then increase the quantity.
    // If not present create a new equipment with the quantity 1.
    if (equipmentCount === 0) {
      var newEquipment: Equipment = { ...equipment };
      newEquipment.quantity = 1;
      equipments.push(newEquipment);
      showAlert("Added to cart!");
    } else {
      // Increase only the quantity.
      var existingEquipment = getEquipment(equipments);
      if (equipment.quantity > equipmentCount) {
        existingEquipment.quantity = Number(equipmentCount) + Number(1);
        showAlert("Added to cart!");
      } else {
        showAlert("Maximum limit reached!");
      }
    }
    globalContext.setSelectedEquipments(equipments);
    setCount(getEquipmentCount(equipments));
  };

  const removeEquipment = () => {
    var equipments = globalContext.selectedEquipments;
    var selectedEquipment = getEquipment(equipments);
    if (selectedEquipment.quantity > 1) {
      selectedEquipment.quantity -= 1;
    } else {
      var index = -1;
      equipments.map((selectedEquipment) => {
        if (equipment.id === selectedEquipment.id) {
          index = equipments.indexOf(selectedEquipment);
        }
      });
      if (index > -1) {
        equipments.splice(index, 1);
      }
    }
    globalContext.setSelectedEquipments(equipments);
    setCount(getEquipmentCount(equipments));
    showAlert("Removed from cart!");
  };

  const validateDataAndAdd = () => {
    if (
      globalContext.startDate.getTime() ===
      new Date("01/01/2000 12:00 am").getTime()
    ) {
      showAlert("From date/time is required!");
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      return;
    }
    if (
      globalContext.endDate.getTime() ===
      new Date("01/01/2000 12:00 am").getTime()
    ) {
      showAlert("To date/time is required!");
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      return;
    }
    if (globalContext.type === "") {
      showAlert("Equipment type is required!");
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      return;
    }
    if (globalContext.startDate < globalContext.endDate) {
      if (equipment.customization1 === undefined && equipment.customization2 === undefined) {
        addEquipment();
      } else {
        globalContext.setCustomizedEquipment(equipment);
        if (equipment.name.toLowerCase().includes("content")) {
          globalContext.setShowContentCafeCustomizationDialog(true);
        } else if (equipment.name.toLowerCase().includes("podcast")) {
          globalContext.setShowPodcastCafeCustomizationDialog(true);
        }
      }
    } else {
      showAlert("Invalid input!");
    }
  };

  const [count, setCount] = useState(
    getEquipmentCount(globalContext.selectedEquipments)
  );

  return (
    <Card sx={{ borderRadius: 3 }}>
      <MobileView>
        <CardContent sx={{ pb: 0 }}>
          <CardMedia
            component="img"
            alt="camera"
            height="220"
            image={equipment.image}
            sx={{
              objectFit:
                equipment.type === "Studio Spaces" || equipment.type === "Content Cafe" ? "cover" : "contain",
              borderRadius: "0.6rem",
            }}
          />
          <Grid container>
            <Grid item xs={8}>
              <Typography
                variant={"h5"}
                fontWeight={800}
                color={"primary.main"}
                sx={{ pt: 2, pb: 1, fontSize: "1.2rem" }}
                noWrap={true}
              >
                {equipment.name}
              </Typography>
            </Grid>
            {equipment.type === "Studio Spaces" || equipment.type === "Content Cafe" ? (
              <Grid item xs={4} sx={{ pt: 2.5, pb: 1, textAlign: "right" }}>
                <Button
                  variant="outlined"
                  sx={{ height: "1.4rem", pl: 0, pr: 0 }}
                  onClick={() => {
                    window.open(
                      equipment.external,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  More
                </Button>
              </Grid>
            ) : null}
          </Grid>
          <Divider textAlign="right">
            <Typography variant={"button"} sx={{ fontSize: "0.9rem" }}>
              {"₹ " + equipment.price + "/HOUR"}
            </Typography>
          </Divider>
          <ul style={dataBoxStyle}>
            {equipment.description.split("|").map((points) => {
              return <li key={Math.random() * 100}>{points}</li>;
            })}
          </ul>
        </CardContent>
        <CardActionArea>
          {count !== 0 ? (
            <ButtonGroup
              variant="contained"
              sx={{
                m: 2,
                width: "-webkit-fill-available",
                borderRadius: 2,
                background: "black",
              }}
            >
              <IconButton sx={{ color: "white", py: 0, px: 2 }}>
                <AddIcon onClick={() => addEquipment()} />
              </IconButton>
              <Button sx={{ width: "-webkit-fill-available", py: 0.5 }}>
                <Typography
                  fontWeight={500}
                  variant={"subtitle1"}
                  color={"primary.light"}
                >
                  {count}
                </Typography>
              </Button>
              <IconButton sx={{ color: "white", py: 0, px: 2 }}>
                <RemoveIcon onClick={() => removeEquipment()} />
              </IconButton>
            </ButtonGroup>
          ) : (
            <Button
              size={"medium"}
              variant="contained"
              disabled={equipment.quantity <= 0}
              sx={{ m: 2, width: "-webkit-fill-available", borderRadius: 2 }}
              onClick={() => validateDataAndAdd()}
            >
              {equipment.quantity <= 0
                ? "Unavailable"
                : equipment.customization1 === undefined || equipment.customization2 === undefined
                ? "Add to cart"
                : "Customise"}
            </Button>
          )}
        </CardActionArea>
      </MobileView>
      <BrowserView>
        <CardContent sx={{ pb: 0, height: "400px" }}>
          <CardMedia
            component="img"
            alt="camera"
            height="220"
            image={equipment.image}
            sx={{
              objectFit:
                equipment.type === "Studio Spaces" || equipment.type === "Content Cafe" ? "cover" : "contain",
              borderRadius: "0.6rem",
            }}
          />
          <Grid container>
            <Grid item xs={8}>
              <Typography
                variant={"h6"}
                fontWeight={800}
                color={"primary.main"}
                sx={{ pt: 2, pb: 1 }}
                noWrap={true}
              >
                {equipment.name}
              </Typography>
            </Grid>
            {equipment.type === "Studio Spaces" || equipment.type === "Content Cafe" ? (
              <Grid item xs={4} sx={{ pt: 2.5, pb: 1, textAlign: "right" }}>
                <Button
                  variant="outlined"
                  sx={{ height: "1.4rem", pl: 0, pr: 0 }}
                  onClick={() => {
                    window.open(
                      equipment.external,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  More
                </Button>
              </Grid>
            ) : null}
          </Grid>
          <Divider textAlign="right">
            <Typography variant={"button"} sx={{ fontSize: "0.9rem" }}>
              {"₹ " + equipment.price + "/HOUR"}
            </Typography>
          </Divider>
          <ul style={dataBoxStyle}>
            {equipment.description.split("|").map((points) => {
              return <li key={Math.random() * 100}>{points}</li>;
            })}
          </ul>
        </CardContent>
        {count !== 0 ? (
          <ButtonGroup
            variant="contained"
            sx={{
              m: 2,
              width: "-webkit-fill-available",
              borderRadius: 2,
              background: "black",
            }}
          >
            <IconButton sx={{ color: "white", py: 0, px: 2 }}>
              <AddIcon onClick={() => addEquipment()} />
            </IconButton>
            <Button sx={{ width: "-webkit-fill-available", py: 0.5 }}>
              <Typography
                fontWeight={500}
                variant={"subtitle1"}
                color={"primary.light"}
              >
                {count}
              </Typography>
            </Button>
            <IconButton sx={{ color: "white", py: 0, px: 2 }}>
              <RemoveIcon onClick={() => removeEquipment()} />
            </IconButton>
          </ButtonGroup>
        ) : (
          <Button
            size={"medium"}
            variant="contained"
            disabled={equipment.quantity <= 0}
            sx={{ m: 2, width: "-webkit-fill-available", borderRadius: 2 }}
            onClick={() => validateDataAndAdd()}
          >
            {equipment.quantity <= 0
              ? "Unavailable"
              : equipment.customization1 === undefined || equipment.customization2 === undefined
              ? "Add to cart"
              : "Customise"}
          </Button>
        )}
      </BrowserView>
    </Card>
  );
}
